import React                from "react";
import Styled               from "styled-components";
import Analytics            from "Utils/Analytics";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Header               from "Components/Core/Header";
import Banners              from "Components/Core/Banners";
import Spaces               from "Components/Core/Spaces";
import Floater              from "Components/Core/Floater";
import Home                 from "Components/Page/Home";
import Space                from "Components/Page/Space";
import Search               from "Components/Page/Search";
import Product              from "Components/Dialog/Product";
import Cart                 from "Components/Dialog/Cart";



// Styles
const Container = Styled.main`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100dvh;
    font-family: var(--store-font);
    padding-bottom: 80px;

    @media (max-width: 600px) {
        --main-padding: 12px;
    }
    @media (max-width: 400px) {
        --product-gap: 12px;
    }
`;

const Content = Styled.div`
    box-sizing: border-box;
    flex-grow: 2;
    width: 100%;
    height: auto;
    max-width: var(--main-width);
    margin: 0 auto;
    padding: 0 var(--main-padding);

    @media (max-width: 600px) {
        padding: 0 var(--main-padding);
    }
`;



/**
 * The App
 * @returns {React.ReactElement}
 */
function App() {
    const {
        updates, currentSpace, currentProduct,
        currentSearch, showSearch, showCart,
    } = Store.useState();

    const fetchProducts = Store.useFetchProducts();
    const fetchResults  = Store.useFetchResults();


    // The References
    const timerRef = React.useRef(null);

    // The Current State
    const [ fetching, setFetching ] = React.useState(false);


    // Variables
    const showSearchPage    = Boolean(showSearch);
    const showSpacePage     = Boolean(!showSearch && currentSpace.spaceID);
    const showProductDialog = Boolean(currentProduct.productID);
    const showHomePage      = !showSpacePage && !showSearch;


    // Scroll to Top
    React.useEffect(() => {
        window.scrollTo({ top : 0, behavior : "instant" });
    }, [ currentSpace.spaceID, showSearch, currentProduct.productID ]);

    // Handles the Scroll
    React.useEffect(() => {
        if (showSpacePage || showSearchPage) {
            window.addEventListener("scroll", handleScroll);
        }
        return () => window.removeEventListener("scroll", handleScroll);
    }, [ updates, showSpacePage, showSearchPage ]);

    // Handles the Scroll
    const handleScroll = async () => {
        const atBottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
        if (atBottom && !fetching) {
            setFetching(true);
            if (showSpacePage) {
                await fetchProducts();
            } else if (showSearchPage) {
                await fetchResults(currentSearch);
            }
            setFetching(false);
        }

        if (showSpacePage && window.scrollY) {
            Utils.clearTimeout(timerRef);
            timerRef.current = window.setTimeout(() => {
                Analytics.scrollInSpace(window.scrollY);
            }, 1000);
        }
    };


    // Do the Render
    return <Container>
        <Header />
        <Content>
            {showHomePage && <Banners />}
            <Spaces />
            {showHomePage && <Home />}
            {showSpacePage && <Space />}
            {showSearchPage && <Search />}
        </Content>
        <Floater />
        <Product open={showProductDialog} />
        <Cart open={showCart} />
    </Container>;
}

export default App;
