/**
 * Returns true if the value is an Object
 * @param {*} value
 * @returns {Boolean}
 */
function isObject(value) {
    return typeof value === "object" && value !== null;
}

/**
 * Returns true if the Object is empty
 * @param {Object} object
 * @returns {Boolean}
 */
function isEmpty(object) {
    return !object || Object.keys(object).length === 0;
}

/**
 * Clones an Object
 * @param {Object} object
 * @returns {Object}
 */
function clone(object) {
    return JSON.parse(JSON.stringify(object));
}

/**
 * Creates an Array of N numbers
 * @param {Number}  amount
 * @param {Number=} start
 * @returns {Number[]}
 */
function createArrayOf(amount, start = 1) {
    const result = [];
    for (let i = start; i < amount + start; i++) {
        result.push(i);
    }
    return result;
}



/**
 * Restores an Item from Local Storage
 * @param {String} key
 * @param {String} defaultValue
 * @returns {String}
 */
function restoreItem(key, defaultValue = "") {
    try {
        const value = window.localStorage.getItem(key);
        return value || defaultValue;
    } catch (e) {
        return defaultValue;
    }
}

/**
 * Stores an Item in Local Storage
 * @param {String} key
 * @param {String} value
 * @returns {Boolean}
 */
function storeItem(key, value) {
    try {
        window.localStorage.setItem(key, value);
        return true;
    } catch (e) {
        return false;
    }
}



/**
 * Clears the Timeout for the auto update
 * @param {React.MutableRefObject<Number>} timerRef
 * @returns {Void}
 */
function clearTimeout(timerRef) {
    if (timerRef.current) {
        window.clearTimeout(timerRef.current);
        timerRef.current = 0;
    }
}

/**
 * Gives an html format to prices
 * @param {Number}   price
 * @param {Boolean=} skipZeros
 * @param {String=}  zeroStr
 * @param {String=}  symbol
 * @returns {String}
 */
function formatPrice(price, skipZeros = false, zeroStr = "", symbol = "$") {
    if (skipZeros && price === 0) {
        return zeroStr || "";
    }

    const sign       = price < 0 ? "-" : "";
    const positive   = Math.abs(price);
    const noCents    = Math.floor(positive);
    const noCentsStr = noCents.toLocaleString(window.navigator.language);
    const cents      = Math.round(positive * 100 - noCents * 100);
    const centsStr   = cents < 10 ? "0" + cents : String(cents);

    return `${symbol} ${sign}${noCentsStr}<sup>${centsStr}</sup>`;
}




// The public API
export default {
    isObject,
    isEmpty,
    clone,
    createArrayOf,

    restoreItem,
    storeItem,

    clearTimeout,
    formatPrice,
};
