import React                from "react";
import Styled               from "styled-components";
import Analytics            from "Utils/Analytics";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Product              from "Components/Core/Product";
import Icon                 from "Components/Utils/Icon";



// Styles
const Header = Styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 32px 0 12px 12px;
`;

const Title = Styled.h2`
    margin: 0;
    font-size: 24px;
    cursor: pointer;
`;

const Link = Styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
        margin-top: 2px;
        line-height: 1;
        font-size: 20px;
    }
    :hover {
        text-decoration: underline;
    }
`;

const List = Styled.section.attrs(({ withSpacing }) => ({ withSpacing }))`
    display: flex;
    padding: 4px;
    gap: var(--product-gap);
    overflow-x: auto;

    ${(props) => props.withSpacing && "margin-top: 32px;"}
`;



/**
 * The Home Page
 * @returns {React.ReactElement}
 */
function Home() {
    const { spaces } = Store.useState();

    const showPage    = Store.useShowPage();
    const getText     = Store.useText();
    const getSpace    = Store.useGetSpace();
    const getProducts = Store.useGetProducts();


    // The References
    const timerRef = React.useRef(null);


    // Handles the Title Click
    const handleTitle = (spaceID) => {
        const space = getSpace(spaceID);
        showPage(space);
        Analytics.gotoSpaceFromTitle(space);
    };

    // Handles the View All Click
    const handleViewAll = (spaceID) => {
        const space = getSpace(spaceID);
        showPage(space);
        Analytics.gotoSpaceFromViewAll(space);
    };

    // Handles the Scroll
    const handleScroll = (e) => {
        Utils.clearTimeout(timerRef);
        timerRef.current = window.setTimeout(() => {
            const totalScroll = e.target.scrollWidth - e.target.offsetWidth;
            Analytics.scrollInHomeSpace(e.target.scrollLeft, totalScroll);
        }, 1000);
    };


    // Calculates the List
    const list = React.useMemo(() => {
        const result = [];
        for (const space of spaces) {
            const items = getProducts(space.spaceID);
            if (items.length) {
                result.push({
                    spaceID : space.spaceID,
                    name    : space.name,
                    items   : items.slice(0, 10),
                });
            }
        }
        return result;
    }, []);



    // Do the Render
    return <>
        {list.map((space) => <React.Fragment key={space.spaceID}>
            <Header>
                <Title onClick={() => handleTitle(space.spaceID)}>
                    {space.name}
                </Title>
                <Link onClick={() => handleViewAll(space.spaceID)}>
                    {getText("showAll")}
                    <Icon icon="next" />
                </Link>
            </Header>
            <List onScroll={handleScroll}>
                {space.items.map((product) => <Product
                    key={product.productID}
                    elem={product}
                />)}
            </List>
        </React.Fragment>)}
    </>;
}

export default Home;
