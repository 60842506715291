import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Components
import Product              from "Components/Core/Product";
import Header               from "Components/Utils/Header";
import Grid                 from "Components/Utils/Grid";
import Html                 from "Components/Utils/Html";



// Styles
const Description = Styled(Html)`
    margin: 0 0 24px calc(32px + 12px + 12px);
`;



/**
 * The Space Page
 * @returns {React.ReactElement}
 */
function Space() {
    const { currentSpace, updates } = Store.useState();

    const showPage    = Store.useShowPage();
    const getProducts = Store.useGetProducts();


    // Calculates the List
    const list = React.useMemo(() => {
        return getProducts(currentSpace.spaceID);
    }, [ currentSpace.spaceID, updates ]);


    // Do the Render
    return <>
        <Header
            title={currentSpace.name}
            onBack={() => showPage()}
        />
        <Description addBreaks>{currentSpace.description}</Description>
        <Grid>
            {list.map((product) => <Product
                key={product.productID}
                elem={product}
            />)}
        </Grid>
    </>;
}

export default Space;
