import React                from "react";
import Styled               from "styled-components";
import Store                from "Utils/Store";

// Components
import Product              from "Components/Core/Product";
import Header               from "Components/Utils/Header";
import Grid                 from "Components/Utils/Grid";



// Styles
const Empty = Styled.div`
    width: 100%;
    text-align: center;
    font-weight: 100;
    font-size: 32px;
`;



/**
 * The Search Page
 * @returns {React.ReactElement}
 */
function Search() {
    const { currentSpace, results } = Store.useState();

    const showPage = Store.useShowPage();
    const getText  = Store.useText();


    // Get the Title
    const title = React.useMemo(() => {
        let result = getText("searchResultsTitle");
        if (currentSpace.spaceID) {
            result += ` - ${currentSpace.name}`;
        }
        return result;
    }, [ currentSpace.spaceID ]);


    // Do the Render
    return <>
        <Header
            title={title}
            onBack={() => showPage(currentSpace)}
        />
        <Grid>
            {results.length > 0 ? results.map((product) => <Product
                key={product.productID}
                elem={product}
            />) : <Empty>{getText("searchNoneResults")}</Empty>}
        </Grid>
    </>;
}

export default Search;
